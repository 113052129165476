import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import CardGrid from '../../../../commons/card/card-grid';

const shadow = 'elevated';
const namespace = 'categories-card';

const CategoryComponent = ({ categories, className = '' }) => (
  <div className={`${className} ${namespace}--grid`}>
    {categories.map((item) => (
      <CardGrid key={item.id} {...{ item, shadow }} preload />
    ))}
  </div>
);

CategoryComponent.propTypes = {
  categories: arrayOf(
    shape({
      title: string,
      target: string,
      picture: shape({
        src: string.isRequired,
      }),
    }),
  ).isRequired,
  className: string,
};

export default CategoryComponent;
