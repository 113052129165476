/**

/**
* Module dependencies
*/
import startApp from '../start-app';

import Categories from '../../shared/components/pages/all-categories/categories.mobile';

startApp(Categories);
