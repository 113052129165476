import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import classnames from 'classnames';

import QuickAccessItem from './components/quick-access-item/index';
import withTracker from '../with-tracker';

const namespace = 'quick-access';

const QuickAccessGrid = ({ className, elements }) => (
  <div className={classnames(`${namespace}--grid`, className)}>
    {elements.map((element) => (
      <QuickAccessItem key={element?.text} {...element} />
    ))}
  </div>
);

QuickAccessGrid.propTypes = {
  className: string,
  elements: arrayOf(shape({})),
};

export default withTracker(QuickAccessGrid);
