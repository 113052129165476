import React from 'react';
import { string, shape } from 'prop-types';
import { MelidataTrack } from 'nordic/melidata/melidata-track';
import { Style } from 'nordic/style';
import { LazyHydrate as Hydrator } from 'nordic/lazy';
import { AndesProvider } from '@andes/context';
import Header from '../../commons/header';
import { SVG_SPRITES } from '../../../../utils/constants';

const Categories = ({
  header,
  siteId,
  canonical,
  deviceType,
  melidataPath,
  melidataEventData,
  melidataExperiments,
  children,
  className,
  csrfToken,
  country,
}) => (
  <div className={className}>
    {/* <MelidataTrack path="/home/categories" type="view" /> */}
    <MelidataTrack
      path={melidataPath}
      type="view"
      event_data={melidataEventData}
      experiments={melidataExperiments}
    />
    <Header {...header} canonical={canonical} siteId={siteId} />
    <Style href={`all-categories.${deviceType}.css`} />
    <Hydrator ssrOnly>{SVG_SPRITES}</Hydrator>
    <AndesProvider locale={country?.locale?.replace('_', '-')} csrfToken={csrfToken}>
      <>{children}</>
    </AndesProvider>
  </div>
);

Categories.propTypes = {
  header: shape({}),
  className: string,
  siteId: string.isRequired,
  canonical: string.isRequired,
  deviceType: string.isRequired,
  melidataPath: string,
  melidataEventData: shape({}),
  melidataExperiments: shape({}),
  children: shape({}),
  csrfToken: string,
  country: shape({
    locale: string,
  }),
};
export default Categories;
