import React from 'react';
import { array, shape, string } from 'prop-types';
import { Script } from 'nordic/script';

import QuickAccessGrid from '../../commons/quick-access/quick-access-grid';
import CategoryGrid from './components/categories-grid/categories-grid';
import Categories from './categories';

const namespace = 'categories';

const CategoriesMobile = ({
  siteId,
  canonical,
  deviceType,
  allCategories: { sections, header },
  melidataPath,
  melidataEventData,
  melidataExperiments,
  csrfToken,
  country,
  serializeProps,
}) => {
  const { quick_access, categories } = sections;
  return (
    <Categories
      header={header}
      deviceType={deviceType}
      canonical={canonical}
      siteId={siteId}
      melidataPath={melidataPath}
      melidataEventData={melidataEventData}
      melidataExperiments={melidataExperiments}
      className={namespace}
      csrfToken={csrfToken}
      country={country}
    >
      <Script>
        {`window.ML_PRELOADED_STATE = ${serializeProps};`}
      </Script>
      <Script src="view-categories.mobile.js" />
      <div className={`${namespace}__sections`}>
        {quick_access && quick_access.order === 'top' && (
          <section className="place quick-access">
            {quick_access.title && (
              <h2 className="place__title">{quick_access.title}</h2>
            )}
            <article className="place__quick-access">
              <QuickAccessGrid elements={quick_access.elements} />
            </article>
          </section>
        )}
        {categories && (
          <section className="place categories-cards">
            <h2 className="place__title">{categories.title}</h2>
            <article className="place__categories">
              <CategoryGrid categories={categories.elements} />
            </article>
          </section>
        )}
        {quick_access && quick_access.order === 'bottom' && (
          <section className="place quick-access quick-access-bottom">
            {quick_access.title && (
              <h2 className="place__title">{quick_access.title}</h2>
            )}
            <article className="place__quick-access">
              <QuickAccessGrid elements={quick_access.elements} />
            </article>
          </section>
        )}
      </div>
    </Categories>
  );
};

CategoriesMobile.propTypes = {
  allCategories: shape({
    sections: shape({ title: string, elements: array }),
    title: string,
    header: shape({ title: string }),
  }),
  melidataPath: string,
  melidataEventData: shape({}),
  melidataExperiments: shape({}),
  siteId: string.isRequired,
  canonical: string.isRequired,
  deviceType: string.isRequired,
  csrfToken: string,
  country: shape({}),
  serializeProps: string,
};

export default CategoriesMobile;
